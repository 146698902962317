import "react-circular-progressbar/dist/styles.css";

import { FunctionComponent } from "react";
import {
  systemStatusColorDecoder,
  systemStatusValueDecoder,
  systemStatusChargerMap,
} from "@utils/systemStatus";

import HeadBar from "@components/headBar";
import ProgressCircle from "./components/progressCircle";
import TempMeter from "./components/tempMeter";
import styles from "./systemStatus.module.scss";
import { useTranslation } from "react-i18next";
import { EssString, EssConfig, EssControl, EssSensor, EssStatus } from "@services/sse/types";
import { useFieldContext } from "@services/index";

const SystemStatus: FunctionComponent = () => {
  const { t: translation } = useTranslation();
  const fields = useFieldContext();
  const { EssString, EssConfig, EssSensor, EssStatus, PCCabinet, M1, M2, M3 } = fields;
  const ChargehubWPower  = parseFloat(M2?.WTot?.toString() ?? "0") + parseFloat(M3?.WL2?.toString() ?? "0") + parseFloat(M3?.WL3?.toString() ?? "0");
  const ChargehubVaPower = parseFloat(M2?.VaTot?.toString() ?? "0");
  const ChargehubVarPower = parseFloat(M2?.VarTot?.toString() ?? "0");
  const ChargehubPowerFactor = parseFloat(M2?.PfTot?.toString() ?? "0");
  const essPowerColor = () => {
    if ((EssStatus?.SysPower || 0) >= 0) {
      //charging
      if ((EssStatus?.SysPower || 0) / 200 === 1) {
        return "#c60202"; //red
      } else if ((EssStatus?.SysPower || 0) / 200 > 0.9) {
        return "#ea9c00"; //yellow
      } else {
        return "#378735"; //green
      }
    } else {
      //discharging
      if (Math.abs(EssStatus?.SysPower || 0) / 600 === 1) {
        return "#c60202"; //red
      } else if (Math.abs(EssStatus?.SysPower || 0) / 600 > 0.9) {
        return "#ea9c00"; //yellow
      } else {
        return "#378735"; //green
      }
    }
  };

  const essSOCColor = () => {
    switch (true) {
      case (EssStatus?.SysSoc || 0) <= 10:
        return "#c60202"; //red
      case (EssStatus?.SysSoc || 0) <= 20:
        return "#ea9c00"; //yellow
      default:
        return "#378735"; //green
    }
  };

  const essVoltageColor = () => {
    switch (true) {
      case (EssStatus?.SysVoltage || 0) >= (EssConfig?.MaxVoltageLimit || 1):
        return "#c60202"; //red
      case (EssStatus?.SysVoltage || 0) >= (EssConfig?.MaxVoltageWarn || 1):
        return "#ea9c00"; //yellow
      case (EssStatus?.SysVoltage || 0) <= (EssConfig?.MinVoltageLimit || -1):
        return "#c60202"; //red
      case (EssStatus?.SysVoltage || 0) <= (EssConfig?.MinVoltageWarn || -1):
        return "#ea9c00"; //yellow
      default:
        return "#378735"; //green
    }
  };

  const realPowerColor = () => {
    switch (true) {
      case (ChargehubWPower || 0) / 1000 >= 190:
        return "#c60202"; //red
      case (ChargehubWPower || 0) / 1000 >= 175:
        return "#ea9c00"; //yellow
      case (ChargehubWPower || 0) / 1000 <= -190:
        return "#c60202"; //red
      case (ChargehubWPower || 0) / 1000 <= -175:
        return "#ea9c00"; //yellow
      default:
        return "#378735"; //green
    }
  };

  const reactivePowerColor = () => {
    switch (true) {
      case (ChargehubVaPower || 0) / 1000 >= 190:
        return "#c60202"; //red
      case (ChargehubVaPower || 0) / 1000 >= 175:
        return "#ea9c00"; //yellow
      case (ChargehubVaPower || 0) / 1000 <= -190:
        return "#c60202"; //red
      case (ChargehubVaPower || 0) / 1000 <= -175:
        return "#ea9c00"; //yellow
      default:
        return "#378735"; //green
    }
  };

  const cellTempColor = (sensorType: any) => {
    return "gray"; // for now, the color is fixed to the default text color (gray) for all values
    switch (true) {
      case sensorType >= (EssConfig?.MaxCellTempLimit || 0):
        return "orange"; //red
      case sensorType >= (EssConfig?.MaxCellTempWarn || 0):
        return "yellow"; //yellow
      case sensorType <= (EssConfig?.MinCellTempLimit || 0):
        return "orange"; //red
      case sensorType <= (EssConfig?.MinCellTempWarn || 0):
        return "yellow"; //yellow
      default:
        return "gray"; //green
    }
  };

  const essTempColor = () => {
    return "gray"; // for now, the color is fixed to the default text color (gray) for all values
    switch (true) {
      case EssSensor?.SysTemp || 0 >= (EssConfig?.MaxSysTempLimit || 1):
        return "orange"; //red
      case EssSensor?.SysTemp || 0 >= (EssConfig?.MaxSysTempWarn || 1):
        return "yellow"; //yellow
      case EssSensor?.SysTemp || 0 <= (EssConfig?.MinSysTempLimit || -1):
        return "orange"; //red
      case EssSensor?.SysTemp || 0 <= (EssConfig?.MinSysTempWarn || -1):
        return "yellow"; //yellow
      default:
        return "gray"; //green
    }
  };
  const pcTempColor = () => {
    return "gray"; // for now, the color is fixed to the default text color (gray) for all values
    switch (true) {
      case PCCabinet?.PCTemp || 0 >= (EssConfig?.MaxSysTempLimit || 1):
        return "orange"; //red
      case PCCabinet?.PCTemp || 0 >= (EssConfig?.MaxSysTempWarn || 1):
        return "yellow"; //yellow
      case PCCabinet?.PCTemp || 0 <= (EssConfig?.MinSysTempLimit || -1):
        return "orange"; //red
      case PCCabinet?.PCTemp || 0 <= (EssConfig?.MinSysTempWarn || -1):
        return "yellow"; //yellow
      default:
        return "gray"; //green
    }
  };

  const stringOn = (() => {
    if (!EssString) return "--";
    for (let i = 0; i < 3; i++) {
      const status = EssString[`String${i + 1}Status` as keyof EssString];
      if (status !== 4) return "OFF";
    }
    return "ON";
  })();

  const { heater, aircon } = (() => {
    if (EssSensor?.SysHvacStatus === undefined) return { heater: "--", aircon: "--" };
    const aircon = [1, 4].includes(EssSensor.SysHvacStatus) ? "ON" : "OFF";
    const heater = [2, 5].includes(EssSensor.SysHvacStatus) ? "ON" : "OFF";
    return { aircon, heater };
  })();

  return (
    <div className={styles.main_container}>
      <HeadBar title="System Status" />
      <div className={styles.main_container2}>
        <div className={styles.diagnostic_SE_BS_con}>
          <div className={styles.diagnostic_SE_con}>
            <div className={styles.diagnostic_SE_upper_con}>
              <div className={styles.diagnostic_SE_upper_con_A}>
                <div className={styles.diagnostic_SE_upper_A_title}>
                  {translation("systemStatus.systemEnvironment")}
                </div>
                <div className={styles.diagnostic_SE_upper_A_status_row}>
                  <div className={styles.diagnostic_SE_upper_A_status_bar1} />
                  <div className={styles.diagnostic_SE_upper_A_status_text}>
                    {translation("systemStatus.warning")}
                  </div>
                  <div className={styles.diagnostic_SE_upper_A_status_bar2} />
                  <div className={styles.diagnostic_SE_upper_A_status_text}>
                    {translation("systemStatus.urgent")}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_A_title3}>
                  {systemStatusChargerMap(EssStatus?.ESSState || 0)}
                </div>

                <div className={styles.diagnostic_SE_con1}>
                  <div className={styles.diagnostic_SE_con1_row}>
                    <div className={styles.diagnostic_SE_con1_param}>
                      {translation("systemStatus.chargeLimit")}
                    </div>
                    <div className={styles.diagnostic_SE_con1_value}>
                      {EssConfig?.ChargeCurrentLimit || 0} A
                    </div>
                  </div>
                  <div className={styles.diagnostic_SE_con1_row}>
                    <div className={styles.diagnostic_SE_con1_param}>
                      {translation("systemStatus.dischargeLimit")}
                    </div>
                    <div className={styles.diagnostic_SE_con1_value}>
                      {Math.abs(EssConfig?.DischargeCurrentLimit || 0)} A
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.diagnostic_SE_upper_con_B}>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.batteryStringOperation")}
                  </div>
                  <div className={styles.diagnostic_SE_upper_row_indicator}>
                    {stringOn}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    Battery Isolation Monitoring
                  </div>
                  <div className={styles.diagnostic_SE_upper_row_indicator}>
                    {/* Note: this is a placeholder for the battery isolation monitoring status */}
                    {"ON"}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    Heater
                  </div>
                  <div className={styles.diagnostic_SE_upper_row_indicator}>
                    {heater}
                  </div>
                </div>
              </div>

              <div className={styles.diagnostic_SE_upper_con_B}>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.airConditioner")}
                  </div>
                  <div className={styles.diagnostic_SE_upper_row_indicator}>
                    {aircon}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.waterSensor")}
                  </div>
                  <div
                    className={styles.diagnostic_SE_upper_row_indicator}
                    style={{
                      color: `${systemStatusColorDecoder(
                        EssSensor?.SysWaterStatus || 0
                      )}`,
                    }}
                  >
                    {EssSensor?.SysWaterStatus !== undefined
                      ? systemStatusValueDecoder(EssSensor.SysWaterStatus)
                      : "--"}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.smokeDetector")}
                  </div>
                  <div
                    className={styles.diagnostic_SE_upper_row_indicator}
                    style={{
                      color: `${systemStatusColorDecoder(
                        EssSensor?.SysSmokeStatus || 0
                      )}`,
                    }}
                  >
                    {EssSensor?.SysSmokeStatus !== undefined
                      ? systemStatusValueDecoder(EssSensor.SysSmokeStatus)
                      : "--"}
                  </div>
                </div>
                <div className={styles.diagnostic_SE_upper_row}>
                  <div className={styles.diagnostic_SE_upper_row_text}>
                    {translation("systemStatus.fireExtinguisher")}
                  </div>
                  <div
                    className={styles.diagnostic_SE_upper_row_indicator}
                    style={{
                      color: `${systemStatusColorDecoder(
                        EssSensor?.SysExtinguisherStatus || 0
                      )}`,
                    }}
                  >
                    {EssSensor?.SysExtinguisherStatus !== undefined
                      ? systemStatusValueDecoder(EssSensor?.SysExtinguisherStatus)
                      : "--"}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.diagnostic_SE_lower_con}>
              <div className={styles.diagnostic_SE_lower_progressbar}>
                <div className={styles.voltage_bottomline}>
                  {(EssStatus?.SysPower || 0) >= 0
                    ? translation("systemStatus.rateOfCharge")
                    : translation("systemStatus.rateOfDischarge")}
                </div>
                <div className={styles.prog_circle}>
                  <ProgressCircle
                    barValue={
                      (EssStatus?.SysPower || 0) >= 0
                        ? ((EssStatus?.SysPower || 0) / 200) * 100
                        : Math.abs((EssStatus?.SysPower || 0) / 600) * 100
                    }
                    barColor={essPowerColor()}
                    numValue={EssStatus?.SysPower || 0}
                    unit="kW"
                  />
                </div>
              </div>
              <div className={styles.diagnostic_SE_lower_progressbar}>
                <div className={styles.voltage_bottomline}>
                  {translation("systemStatus.stateOrCharge")} <br /> (
                  {(((EssStatus?.SysSoc || 0) / 100) * 486).toFixed(0)} kWh)
                </div>
                <div className={styles.prog_circle}>
                  <ProgressCircle
                    barValue={EssStatus?.SysSoc || 0}
                    barColor={essSOCColor()}
                    numValue={EssStatus?.SysSoc || 0}
                    unit="%"
                  />
                </div>
              </div>
              <div className={styles.diagnostic_SE_lower_progressbar}>
                <div className={styles.voltage_bottomline}>
                  {translation("systemStatus.relativeHumidity")}
                </div>
                <div className={styles.prog_circle}>
                  <ProgressCircle
                    barValue={EssSensor?.SysHumidity || 0}
                    barColor="#378735" //placeholder
                    numValue={EssSensor?.SysHumidity || 0}
                    unit="%"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.diagnostic_BS_con}>
            <div className={styles.bottom_container_header}>
              {translation("systemStatus.temperatureStatus")}
            </div>
            <div className={styles.diagnostic_BS_temp_con}>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={translation("systemStatus.minCellTemperature")}
                  color={cellTempColor(EssStatus?.CellMinTemp || 0)}
                  data={EssStatus?.CellMinTemp || 0}
                />
              </div>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={"Max. Cell Temperature"}
                  color={cellTempColor(EssStatus?.CellMaxTemp || 0)}
                  data={EssStatus?.CellMaxTemp || 0}
                />
              </div>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={translation("systemStatus.avgCellTemperature")}
                  color={cellTempColor(EssStatus?.CellAvgTemp || 0)}
                  data={EssStatus?.CellAvgTemp || 0}
                />
              </div>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={translation(
                    "systemStatus.batteryEnclosureTemperature"
                  )}
                  color={essTempColor()}
                  data={EssSensor?.SysTemp || 0}
                />
              </div>
              <div className={styles.bottom_container_right_temp}>
                <TempMeter
                  title={"Power Converter Enclosure Temperature"}
                  color={pcTempColor()}
                  data={PCCabinet?.PCTemp || 0}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mid_container}>
          <div className={styles.mid_container_left_header}>
            {translation("systemStatus.electricalStatus")}
          </div>

          <div className={styles.mid_container_left_progressbarlist}>
            <div className={styles.mid_container_left_column}>
              <div className={styles.mid_container_left_bottomline}>
                {translation("systemStatus.systemVoltage")}
              </div>
              <div className={styles.mid_container_left_progressbar}>
                <ProgressCircle
                  barValue={
                    (EssStatus?.SysVoltage || 0 / (EssConfig?.MaxVoltageLimit || 1)) * 100
                  }
                  barColor={essVoltageColor()}
                  numValue={EssStatus?.SysVoltage || 0}
                  unit="V"
                />
              </div>
            </div>
            <div className={styles.mid_container_left_column}>
              <div className={styles.mid_container_left_bottomline}>
                {translation("systemStatus.chargehubRealPower")}
              </div>
              <div className={styles.mid_container_left_progressbar}>
                <ProgressCircle
                  barValue={
                    (Math.abs(ChargehubWPower || 0) /
                      (200 * 1000)) *
                    100
                  }
                  barColor={realPowerColor()}
                  numValue={Number(
                    ((ChargehubWPower || 0) / 1000).toFixed(2)
                  )}
                  unit="kW"
                />
              </div>
            </div>
            <div className={styles.mid_container_left_column}>
              <div className={styles.mid_container_left_bottomline}>
                {translation("systemStatus.chargehubReactivePower")}
              </div>
              <div className={styles.mid_container_left_progressbar}>
                <ProgressCircle
                  barValue={
                    (Math.abs(ChargehubVarPower || 0) /
                      (200 * 1000)) *
                    100
                  }
                  barColor={reactivePowerColor()}
                  numValue={Number(
                    ((ChargehubVarPower || 0) / 1000).toFixed(2)
                  )}
                  unit="kVar"
                />
              </div>
            </div>
            <div className={styles.mid_container_left_column}>
              <div className={styles.mid_container_left_bottomline}>
                {translation("systemStatus.powerFactor")}
              </div>
              <div className={styles.mid_container_left_progressbar}>
                <ProgressCircle
                  barValue={
                    Math.abs(ChargehubPowerFactor || 0) * 100
                  }
                  barColor="#378735" //placeholder
                  numValue={Number(
                    (ChargehubPowerFactor || 0).toFixed(2)
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemStatus;
